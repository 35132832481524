(function() {
    window.onhashchange = function(e) {
        if (location.hash === "#contact-us") {
            location.hash = "";
            var onMobile = jQuery(document.body).width() < 769;
            scrollToId("#contact-us", onMobile ? 0 : -175);
            e.preventDefault();
            return false;
        }
    };

      function scrollToId(idSelector, offset) {
        jQuery("html, body").animate({ scrollTop: (jQuery(idSelector).offset().top + offset) + "px" });
      }
    })();