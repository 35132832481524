


(function() {
    var expanders = jQuery('.expander-content');
    var allExpanded = false;

    if(expanders.length > 1) {
        var expandAll = jQuery("<button class='btn btn-primary expand-all'>Expand All</button>").click(function() {
            expanders.children('header').toggleClass('expanded', allExpanded = !allExpanded);
            expandAll.text(allExpanded ? "Collapse All" : "Expand All");
        }).insertBefore(expanders[0]);

        expandAll.wrap("<div class='text-right'></div>");
    }

    expanders.children('header').removeClass('expanded');
})();