(function() {

    var menuItems = jQuery('#menu-header-menu').children('.menu-item');

    function showSearch() {
        jQuery("#click-to-show-search").remove();
        jQuery(".search-field, .search-form button[type='submit']").addClass("show");
    }
    jQuery("#click-to-show-search").on("click", showSearch);

    function toggleMobileMenu() {
        jQuery("#main-menu").toggleClass("show");
    }
    jQuery(".open-menu-btn").on("click", toggleMobileMenu);

    function showHeaderIntro() {
        var $intro = jQuery(".home-page-intro");
        if ($intro.length)
        {
            $intro.find("div").addClass("show");
            setTimeout(function () {
                $intro.find("a").addClass("show");
            }, 250);
        }
    }

    (function() {
        jQuery("#menu-header-menu li").on("hover", function (ev) {
            var $element = jQuery(this);
            var id = $element.attr("id");
            if (id && $element.hasClass("menu-item-has-children")) {
                id = id.replace("menu-item-","");
                jQuery(".sub-menu").removeClass("open");
                jQuery(".sub-menu[data-id='" + id + "']").addClass("open");
                jQuery("header").addClass("open");
            }
            else {
                jQuery(".sub-menu").removeClass("open");
                jQuery("header").removeClass("open");
            }
        });

        jQuery("header").on("mouseleave", function () {
            jQuery(".sub-menu").removeClass("open");
            jQuery("header").removeClass("open");
            menuItems.removeClass('hover');
        });

        jQuery(function() {
          jQuery('#home-banner').slick({
            dots: false,
            arrows: true,
            centerMode: false,
            adaptiveHeight: true,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>'
          });
        });
    })();

    jQuery('#home-banner').on('init', function () {
      setTimeout(function() {
        showHeaderIntro();
      }, 1000);
    });
    var $body = jQuery("body");

    var scrollContainer = jQuery(window).on("scroll", function() {
      $body.children("header").toggleClass("short", jQuery(this).scrollTop() > 0);

    });

    menuItems.hover(function() {
        var item = jQuery(this);
        menuItems.removeClass('hover');
        item.addClass('hover');
    });

    menuItems.on('click', function (ev) {

        if (jQuery(ev.target).parent().hasClass("sub-menu")) {
            return;
        }

        var $this = jQuery(this).toggleClass('show');
        if (jQuery(window).width() < 768) {
            $this.find('.sub-menu').toggleClass('show');
        }
    });

})();